<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="
        isNewRecord ? $t('categories_create.breadcrumbs-list-label') : $t('categories_edit.breadcrumbs-list-label')
      "
      :has-back="true"
      :button-back-link="'/categories'"
    >
      <template v-slot:action>
        <div class="d-flex align-items-center">
          <button v-if="!isNewRecord" class="btn btn-checkbox px-1-5 py-0-8 mr-1-5" @click="deleteCategory()">
            <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

            <span class="font-weight-regular roboto-font text-black">{{ $t('common.delete-btn') }}</span>
          </button>

          <button class="btn btn-primary px-4 py-1" @click="submitForm">
            <span class="font-weight-medium poppins-font">{{ $t('common.submit-btn') }}</span>
          </button>
        </div>
      </template>
    </PageNavigator>

    <div class="full-page-height">
      <div class="px-1-5 pt-7-5">
        <form>
          <div class="row">
            <div v-for="(field, key) in formFields" :key="`field-${key}`" class="col">
              <div>
                <label :for="`${field.name}`" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
                  {{ $t(field.i18KeyLabel) }}
                </label>

                <input
                  :id="field.name"
                  v-model="field.value"
                  v-validate="field.validationRules"
                  :data-vv-as="$t(field.i18KeyLabel)"
                  :name="field.name"
                  :type="field.tyle"
                  :placeholder="$t(field.i18KeyPlaceholder)"
                  class="form-control w-100 px-1-5"
                />

                <span v-if="errors.has(field.name)" class="error-message mt-1 col-12 pl-0">
                  {{ errors.first(field.name) }}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import { mapGetters, mapActions } from 'vuex'
import { mapValues, keyBy } from 'lodash'

export default {
  name: 'Form',
  components: {
    PageNavigator
  },
  data() {
    return {
      formFields: [
        {
          name: 'title',
          value: '',
          validationRules: 'required|max:100',
          i18KeyLabel: 'Category name',
          type: 'text'
        }
      ],
      createSuccessMessage: {
        type: 'Sucess!',
        messageI18Key: 'common.alerts.actions.created'
      },
      updateSuccessMessage: {
        type: 'Sucess!',
        messageI18Key: 'common.alerts.actions.updated'
      },
      deletedSuccessMessage: {
        type: 'Sucess!',
        messageI18Key: 'common.alerts.actions.deleted'
      },
      errorMessage: {
        type: 'Error!',
        messageI18Key: 'common.alerts.actions.global_error'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      getRouteToRedirectFromCategoriesPage: 'categories/getRouteToRedirectFromCategoriesPage'
    }),
    categoryId() {
      return this.$route.params.id
    },
    isNewRecord() {
      return !this.categoryId
    }
  },
  mounted() {
    if (this.categoryId) {
      this.fetchCategory({ category_id: this.categoryId })
        .then(result => {
          this.setFormData(result)
        })
        .catch(errors => {
          if (errors) {
            this.setError(this.errorMessage)
            this.$router.push('/categories')
          }
        })
    }
  },
  methods: {
    ...mapActions({
      create: 'categories/create',
      update: 'categories/update',
      delete: 'categories/deleteCategory',
      fetchCategory: 'categories/getOne',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    setFormData(data) {
      for (let [key, value] of Object.entries(data)) {
        this.formFields.find(field => {
          if (field.name === key) field.value = value
        })
      }
    },
    deleteCategory() {
      this.delete(this.categoryId)
        .then(result => {
          this.setSuccess(this.deletedSuccessMessage)
          this.$router.push('/categories')
        })
        .catch(errors => {
          this.setError(this.errorMessage)
        })
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let payload = {
            ...mapValues(keyBy(this.formFields, 'name'), 'value')
          }

          payload = {
            ...payload,
            ...{ salon_id: this.accountInfo.salon_id }
          }

          if (!this.categoryId) {
            this.create(payload)
              .then(result => {
                if (result) {
                  this.setSuccess(this.createSuccessMessage)
                  if (this.getRouteToRedirectFromCategoriesPage) {
                    this.$router.push(this.getRouteToRedirectFromCategoriesPage)
                  } else this.$router.push('/categories')
                }
              })
              .catch(errors => {
                this.setError(this.errorMessage)
              })
          } else {
            payload = {
              ...payload,
              ...{ category_id: this.categoryId }
            }

            this.update(payload)
              .then(result => {
                if (result) {
                  this.setSuccess(this.updateSuccessMessage)
                  this.$router.push('/categories')
                }
              })
              .catch(errors => {
                this.setError(this.errorMessage)
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
